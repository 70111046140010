<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
            <v-card-title class="headline d-flex justify-center">Оберіть мову/Choose language</v-card-title>
            <v-card-text>
                <div class="d-flex justify-center">
                    <v-btn color="primary" class="mx-2" @click="setLanguage('en')">English</v-btn>
                    <v-btn color="primary" class="mx-2" @click="setLanguage('ua')">Українська</v-btn>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: true
        };
    },
    methods: {
        setLanguage(language) {
            localStorage.setItem('language', language);
            this.$emit('language-selected', language);
            this.dialog = false;
        }
    }
};
</script>