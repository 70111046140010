import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import Missing from "../views/404.vue";
import Demo from "../views/Demo.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   component: Demo,
  //   meta: {
  //     title: "Trydo || Creative Agency And Multipurpose Template",
  //   },
  // },

  {
    path: "/",
    name: "Main",
    meta: {
      title: "Головна",
    },
    component: () => import("../views/all-home-version/DigitalAgency.vue"),
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "Про нас",
    },
    component: () => import("../views/all-home-version/MinimalPortfolio.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: {
      title: "Контакти",
    },
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "*",
    component: Missing,
    meta: {
      title: "404 || VueJS Multipurpose Template",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
