import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    ua: {
        message: {
            1: 'Про нас',
            2: ' Хто ми?',
            3: ` DriveZone – компанія з проєктування та будівництва екстримальних атракціонів:
            тролеїв, мотузкових парків, скалодромів. За плечами професіоналів DriveZone вже є декілька проєктів,
            один з яких - це найдовший тролей в Україні.
            Ми не просто створюємо надійні і безпечні атракціони, з DriveZone ви отримаєте якісний, унікальний
            продукт, який буде відповідати очікуванням клієнта.`,
            4: 'Ми відповідаємо за вашу безпеку:',
            5: 'Тролей в Дніпрі',
            6: 'Тролей в Буковелі',
            7: 'Безпека',
            8: ` Політ на Тролеї – це справжня повітряна пригода! Менш ніж за хвилину ви прокотитеся від оглядового майданчику в парку ім.Т.Г. Шевченка, через річку, до Монастирського остріву.

            Крім того, ми надамо вам можливість зняти на відео свій політ за допомогою екшн камери Go pro. Широкий кут зйомки камери дозволить відобразити оглядове відео спуску на тролеї.
            
            З радістю додамо до ваших колекцій вражень нову повітряну пригоду – переправу над річкою! `,
            9: ` Найдовший, а можливо, і найекстремальніший тролей в Україні вже відкритий у популярному курортному місці Буковель.

            Вже сьогодні ви можете випробувати свої нерви на міцність. За 100 секунд ви зможете пролетіти від вершини гори Буковель до центру курорту - на дах торгового центру Бука! Довжина тролея 1130 м із перепадом висоти понад 190 м! Атракціон має 3 лінії і входить до десятки найдовших у Європі!
            
            Тролей "Буковель" - це справжня повітряна пригода! Приїжджайте та переконайтесь у цьому самі!`,
            10: ` Один із головних пріоритетів нашої роботи – це безпека. Безпека на наших атракціонах – це безпека всіх складових: професійна команда будівельників, спорядження, що відповідає світовим стандартам, сертифіковані інструктори.
            Крім того, всі відвідувачі наших атракціонів проходять обов'язковий інструктаж щодо поведінки на об'єктах, а за безпекою відпочиваючих стежать інструктори.`,
            11: 'Ми використовуємо лише сертифіковане спорядження світових виробників',
            12: 'Ми проводимо регулярні перевірки безпеки наших атракціонів',
            13: 'Ми завжди використовуємо дублюючі системи страховки',
            14: 'Наші інструктори проходять спеціальне навчання для забезпечення безпеки наших відвідувачів',
            15: 'Головна',
            16: 'Про нас',
            17: 'Контакти',
            18: 'Ваші фото/відео',
            19: 'Буковель',
            20: 'Найдовший тролей в Україні 1130м',
            21: 'Дніпро',
            22: 'Незабутній тролей в Дніпрі.',
            23: 'Українська компанія, по створенню екстримальних атракціонів',
            24: `Зв'яжіться з нами`,
            25: `Ми - команда професіоналів, пристрасно віддана створенню інноваційних та захоплюючих атракціонів. Ми не
            просто проєктуємо та будуємо екстремальні розваги, ми створюємо можливості для незабутніх пригод та
            емоцій.
            Наша мета – не просто задовольнити очікування клієнтів, а перевершити їх, надавши висококласні, безпечні
            та
            унікальні атракціони. Разом з DriveZone ви можете бути впевнені, що ваш проєкт стане яскравим і
            незабутнім
            місцем для всіх, хто шукає адреналін і веселощі.`,
            26: `Зв'яжіться з нами любим зручним для вас способом.`,
            27: 'Номер телефону:',
            28: 'Пошта',
            29: 'парк ім Т.Г. Шевченко, оглядовий майданчик',
            30: 'оглядовий майданчик, верх лижної траси 2А',
            31: 'Локація',
            32: 'Для вирішення питань з завантаженням фото:',
            33: '',
            34: '',
        }
    },
    en: {
        message: {
            1: 'About us',
            2: 'Who are we?',
            3: ` DriveZone is a company that designs and builds extreme attractions:
            trolleys, rope parks, climbing walls. DriveZone professionals already have several projects behind them,
            one of which is the longest trolley in Ukraine.
            We do not just create reliable and safe attractions, with DriveZone you will get a high-quality, unique one
            a product that will meet the client's expectations.`,
            4: 'We are responsible for your safety:',
            5: 'Trolley in the Dnipro',
            6: 'Trolley in Bukovel',
            7: 'Security',
            8: `Flying on the Trolley is a real aerial adventure! In less than a minute, you will ride from the observation deck in the park named after T.G. Shevchenko, across the river, to Monastyrsky Island.

            In addition, we will provide you with the opportunity to film your flight with a Go pro action camera. The wide shooting angle of the camera will allow you to display an overview video of the descent on the trolley.
            
            We are happy to add a new aerial adventure to your collection of experiences - a river crossing!`,
            9: `The longest, and possibly the most extreme trolley in Ukraine has already opened in the popular resort town of Bukovel.

            Already today you can test your nerves. In 100 seconds, you can fly from the top of Bukovel Mountain to the center of the resort - on the roof of the Buka shopping center! The length of the trolley is 1130 m with a height difference of more than 190 m! The attraction has 3 lines and is among the ten longest in Europe!
            
            Trolley "Bukovel" is a real aerial adventure! Come and see for yourself!`,
            10: `One of the main priorities of our work is safety. Safety at our attractions is the safety of all components: a professional construction team, equipment that meets world standards, certified instructors.
            In addition, all visitors to our attractions undergo mandatory instruction on behavior at the facilities, and the safety of vacationers is monitored by instructors.`,
            11: 'We use only certified equipment from global manufacturers',
            12: 'We conduct regular safety inspections of our attractions',
            13: 'We always use duplicate insurance systems',
            14: 'Our instructors undergo special training to ensure the safety of our visitors',
            15: 'Main',
            16: 'About us',
            17: 'Contacts',
            18: 'Your photos/videos',
            19: 'Bukovel',
            20: 'The longest trolley in Ukraine is 1130 m',
            21: 'Dnipro',
            22: 'An unforgettable trolley in the Dnipro.',
            23: 'Ukrainian company for the creation of extreme attractions',
            24: `Contact us`,
            25: `We are a team of professionals passionately committed to creating innovative and exciting attractions. We are not
            simply designing and building extreme entertainment, we create opportunities for unforgettable adventures and
            emotions
            Our goal is not just to meet customer expectations, but to exceed them by providing high-quality, safe
            and
            unique attractions. Together with DriveZone, you can be sure that your project will become bright and
            unforgettable
            a place for everyone who is looking for adrenaline and fun.`,
            26: 'Contact us in any way convenient for you.',
            27: 'Phone number:',
            28: 'Email',
            29: 'park named after T.G. Shevchenko, observation deck',
            30: 'observation deck, the top of ski track 2A',
            31: 'Location',
            32: 'To solve problems with uploading photos:',
            33: '',
            34: '',
            35: '',
        }
    }
};

const i18n = new VueI18n({
    locale: localStorage.getItem('language') || 'ua',
    fallbackLocale: 'en',
    messages,
});

export default i18n;