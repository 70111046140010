<template>
  <div>
    <v-navigation-drawer v-model="drawer" absolute temporary width="320">
      <v-list-item class="pa-5">
        <div class="logo">
          <img src="../../assets/images/logo/logo.png" width="150px" alt="Creative Agency Logo" />
        </div>
        <v-spacer></v-spacer>
        <v-btn class="close-icon" icon @click="drawer = !drawer" v-html="iconSvg(closeIcon)">
        </v-btn>
      </v-list-item>

      <v-list>
        <v-list-item :ripple="false" v-for="item in items" :key="item.title" :to="item.to" link>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- End mobile menu sidebar item list -->
      </v-list>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->
    <v-app-bar dark elevation="0" color="transparent" absolute class="default-header">
      <router-link to="/" class="logo">
        <img src="../../assets/images/logo/logo.png" width="200px" alt="Creative Agency Logo" />
      </router-link>
      <!-- End brand logo -->
      <v-spacer></v-spacer>
      <LanguageSwitcher />
      <v-btn icon class="ma-0 pa-0 hidden-md-and-up" @click="drawer = !drawer" v-html="iconSvg(icon)">
      </v-btn>
      <!-- End mobile menu icon -->
      <v-btn :ripple="false" class="hidden-md-and-down" text to="/">{{ $t('message.15') }}</v-btn>
      <!-- End Home variants dropdown -->
      <v-btn :ripple="false" class="hidden-md-and-down" text to="/about">{{ $t('message.16') }}</v-btn>
      <!-- End pages dropdown -->

      <!-- End blocks dropdown -->
      <v-btn :ripple="false" class="hidden-md-and-down" text to="/contact">{{ $t('message.17') }}</v-btn>
      <!-- <a class="rn-btn" href="https://themeforest.net/checkout/from_item/30195230?license=regular">
        <span>Ваші фото/відео</span>
      </a> -->
      <!-- End header menu item -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
import feather from "feather-icons";
import LanguageSwitcher from '@/components/service/LanguageSwitcher.vue';

export default {
  components: {
    LanguageSwitcher
  },
  data() {
    return {
      drawer: false,
      items: [],
      icon: "menu",
      closeIcon: "x",
    };
  },
  created() {
    this.items = [
      { title: this.$t('message.15'), to: "/" },
      { title: this.$t('message.16'), to: "/about" },
      { title: this.$t('message.17'), to: "/contact" },
    ];
  },
  watch: {
    '$i18n.locale'() {
      this.updateItems();
    }
  },
  methods: {
    updateItems() {
      this.items = [
        { title: this.$t('message.15'), to: "/" },
        { title: this.$t('message.16'), to: "/about" },
        { title: this.$t('message.17'), to: "/contact" },
      ];
    },
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>

<style lang="scss">
.feather-menu {
  color: #c6c9d8;
}
</style>