<template>
    <div class="language-switcher">
        <v-btn v-for="language in languages" :key="language.value" @click="selectLanguage(language.value)" outlined
            small class="mr-2">{{ language.text }}</v-btn>
    </div>
</template>

<script>
export default {
    data() {
        return {
            languages: [
                { text: 'EN', value: 'en' },
                { text: 'Укр', value: 'ua' },
            ],
            selectedLanguage: ''
        };
    },
    created() {
        this.selectedLanguage = localStorage.getItem('language') || 'en';
        this.$i18n.locale = this.selectedLanguage;
    },
    methods: {
        selectLanguage(language) {
            this.selectedLanguage = language;
            localStorage.setItem('language', language);
            this.$i18n.locale = language;
        }
    }
};
</script>

<style scoped>
.language-switcher {
    display: flex;
}
</style>